import React, { useRef } from 'react';
import { TopNav } from './landing_components/TopNav';
import { Hero } from './landing_components/Hero';
import { HowWeWork } from './landing_components/HowWeWork';
import { Services } from './landing_components/Services';
import { Footer } from './landing_components/Footer';
import { Portfolio } from './landing_components/Portfolio';
import { Contact } from './landing_components/Contact';
import { Endorsements } from './landing_components/Endorsements';

export function LandingPage() {

    const howWeWorkRef = useRef(null);
    const serviceBespokeSoftware = useRef(null);
    const serviceExcelSheet = useRef(null);
    const serviceCustomSaaS = useRef(null);
    const portfolioRef = useRef(null);
    const contactRef = useRef(null);

    return (
        <>
            <TopNav howWeWorkRef={howWeWorkRef} serviceBespokeSoftware={serviceBespokeSoftware} serviceExcelSheet={serviceExcelSheet} serviceCustomSaaS={serviceCustomSaaS} portfolioRef={portfolioRef}  contactRef={contactRef}/>
            <div className="w-full overflow-y-auto overflow-x-hidden">
                <Hero/>
                <HowWeWork ref={howWeWorkRef}/>
                <Services serviceBespokeSoftware={serviceBespokeSoftware} serviceExcelSheet={serviceExcelSheet} serviceCustomSaaS={serviceCustomSaaS}/>
                <Endorsements/>
                <Portfolio ref={portfolioRef}/>
                <Contact ref={contactRef}/>
                <Footer/>
            </div>
        </>
    );
}
