import React, { RefObject } from "react";
import { ServiceBespoke } from "./ServiceBespoke";
import { ServiceExcel } from "./ServiceExcel";
import { ServiceSaaS } from "./ServiceSaaS";

interface ServicesProps {
    serviceBespokeSoftware: RefObject<HTMLDivElement>;
    serviceExcelSheet: RefObject<HTMLDivElement>;
    serviceCustomSaaS: RefObject<HTMLDivElement>;
}

export function Services({ serviceBespokeSoftware, serviceExcelSheet, serviceCustomSaaS }: ServicesProps) {
    return(
        <div className="flex flex-col items-center bg-base-200 services-section">
            <div className="prose p-8">
                <h1>Services</h1>
            </div>

            <div className="flex flex-col lg:flex-row w-full justify-around items-center max-w-[1500px]">
                <ServiceBespoke ref={serviceBespokeSoftware}/>
                <ServiceExcel ref={serviceExcelSheet}/>
                <ServiceSaaS ref={serviceCustomSaaS}/>
            </div>
        </div>
    );
}