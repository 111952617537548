import React from "react";
import osrs_gold from '../../common/images/osrs_gold.png';
import gnome_trader from '../../common/images/gnome_trader_img_2.png'


export function PortfolioGnomeTrader(){
    return(
        <>
            <article className="prose pb-12 px-2">
                <h2 className="">gnome_trader</h2>
                <a className="link" target="_blank" href="https://www.gnometrader.app/">https://www.gnometrader.app</a>
                <p>
                    Our flagship product is Gnome Trader, it is a world first cryptocurrency bridge between Solana and "old school runescape" and is fully automated. It is live and version 2.0 is currently being built.
                </p>
                <h2>Tech Stack:</h2>
                <blockquote>
                    FastAPI<br/>
                    React<br/>
                    PostgreSQL<br/>
                    Heroku<br/>
                    Phantom Wallet<br/>
                    Solana Blockchain<br/>
                </blockquote>
            </article>
            <div className="mockup-browser border bg-red-500 w-full overflow-hidden max-w-[1000px]">
                <div className="mockup-browser-toolbar">
                    <a className="input">https://www.gnometrader.app</a>
                </div>
                <div className="flex justify-center bg-base-100">
                    {/* <img src={osrs_gold} alt="osrs_gold" className="w-full" style={{ width: '40%', height: 'auto' }}/> */}
                    <img src={gnome_trader} alt="gnome_trader" className="w-full"/>
                </div>
            </div>
            <br className="py-12"/>
        </>
    );
}