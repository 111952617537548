import React, { forwardRef } from "react";
import excelLogo from '../../common/images/excel_logo.png';

interface ServiceExcelProps {

}

export const ServiceExcel = forwardRef<HTMLDivElement, ServiceExcelProps>(  
    (props, ref) => {
    return(
        <div ref={ref} className="card w-96 bg-base-100 shadow-xl min-h-[515px] mt-4 service-bespoke-section">
            <figure><img src={excelLogo} alt="Excel_Img" className="py-10" style={{ width: '40%', height: 'auto' }}/></figure>
            <div className="card-body bg-green-600 rounded-b-2xl">
                <h2 className="card-title text-white">Excel Sheet Replacement</h2>
                <p className="text-white">If your business is running on awful excel sheet systems, we can recompile all these processes into a website to suit your specific needs. When looking at a client, we aim for full spectrum automation of their business.</p>
                <div className="card-actions justify-end">
                </div>
            </div>
        </div>
    );
});