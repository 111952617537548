import React from 'react';
import cory_zue_img from "../../common/images/cory_zue.jpeg";

export function Endorsements(){
    return(
        <div className="flex flex-row items-center justify-center bg-base-200 pt-12">
            <div className="stats shadow">
                <div className="stat">
                    <div className="flex flex-col">


                        <div className="flex flex-row items-center ">
                            <div className="avatar">
                                <div className="w-24 rounded-full">
                                    <img src={cory_zue_img} alt="cory_zue"/>
                                </div>
                            </div>
                            <div className="prose p-2">
                                <blockquote>"SaaS Pegasus powers many of Black Sun Systems digital solutions, saving loads of development time and cost for their clients."<br/>-Cory Zue, Founder of <a href="https://www.saaspegasus.com/">SaaS Pegasus</a></blockquote>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

