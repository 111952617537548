import React, { forwardRef } from "react";

export const Contact = forwardRef<HTMLDivElement, {}>((props, ref) => {
    return (
        <div ref={ref} className="flex flex-col items-center bg-base-200 portfolio-section">
            <div className="prose p-8">
                <h1>Contact</h1>
            </div>
            <div className="mockup-code w-full max-w-[1000px]">
                    <pre data-prefix=">"><code>Black Sun Systems</code></pre>
                    <pre data-prefix=">"><code>Phone: <span className="text-success">+44 07429316117</span></code></pre>
                    <pre data-prefix=">"><code>Email: <span className="text-success">ceo@blacksunsystems.co.uk</span></code></pre>
                    <pre data-prefix=">"><code></code></pre>
                    <pre data-prefix=">"><code>Even if you arent sure what </code></pre>
                    <pre data-prefix=">"><code>you're after, we can help you </code></pre>
                    <pre data-prefix=">"><code>figure out what you want.</code></pre>
                </div>
        </div>
    );
});



