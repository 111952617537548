import React, { forwardRef } from "react";

interface ServiceSaaSProps {

}

export const ServiceSaaS = forwardRef<HTMLDivElement, ServiceSaaSProps>(
    (props, ref) => {
        return(
            <div ref={ref} className="card w-96 bg-base-100 shadow-xl min-h-[515px] mt-4 service-bespoke-section">
                <figure className="py-4"><StripeSVG/></figure>
                <div className="card-body bg-orange-400 rounded-b-2xl">
                    <h2 className="card-title text-white">Custom SaaS Software</h2>
                    <p className="text-white">Have a Software as a Service Idea you want to create? Our development pipeline uses the SaaSPegasus package for light speed SaaS development which uses the Stripe payment system. If we like your idea enough we would offer no development costs in return for equity.</p>
                    <div className="card-actions justify-end">
                    </div>
                </div>
            </div>
        );
});

function StripeSVG(){
    return(
        <svg 
        xmlns="http://www.w3.org/2000/svg" 
        width="200" 
        height="200" 
        fill="blue" 
        className="bi bi-stripe" 
        viewBox="0 0 16 16">
            <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm6.226 5.385c-.584 0-.937.164-.937.593 0 .468.607.674 1.36.93 1.228.415 2.844.963 2.851 2.993C11.5 11.868 9.924 13 7.63 13a7.7 7.7 0 0 1-3.009-.626V9.758c.926.506 2.095.88 3.01.88.617 0 1.058-.165 1.058-.671 0-.518-.658-.755-1.453-1.041C6.026 8.49 4.5 7.94 4.5 6.11 4.5 4.165 5.988 3 8.226 3a7.3 7.3 0 0 1 2.734.505v2.583c-.838-.45-1.896-.703-2.734-.703"/>
        </svg>
    );
}