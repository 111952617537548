import React, { RefObject, useState } from 'react';
import { Logo } from '../../common/Logo';
import { DesktopButtons } from './DesktopButtons';
import { MobileButtons } from './MobileButtons';

interface TopNavProps {
    howWeWorkRef: RefObject<HTMLDivElement>;
    serviceBespokeSoftware: RefObject<HTMLDivElement>;
    serviceExcelSheet: RefObject<HTMLDivElement>;
    serviceCustomSaaS: RefObject<HTMLDivElement>;
    portfolioRef: RefObject<HTMLDivElement>;
    contactRef: RefObject<HTMLDivElement>;
}

export function TopNav({ howWeWorkRef, serviceBespokeSoftware, serviceExcelSheet, serviceCustomSaaS, portfolioRef, contactRef }: TopNavProps) {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    return (
        <div className="bg-base-100 text-base-content sticky top-0 z-30 flex h-16 w-full justify-center bg-opacity-90 backdrop-blur transition-shadow duration-100 [transform:translate3d(0,0,0)]">
            <div className="navbar bg-base-200">
                <div className="flex-1">
                    <Logo/>
                </div>
                {isMobile ? <MobileButtons howWeWorkRef={howWeWorkRef} serviceBespokeSoftware={serviceBespokeSoftware} portfolioRef={portfolioRef} contactRef={contactRef}/> : <DesktopButtons howWeWorkRef={howWeWorkRef} serviceBespokeSoftware={serviceBespokeSoftware} serviceExcelSheet={serviceExcelSheet} serviceCustomSaaS={serviceCustomSaaS} portfolioRef={portfolioRef} contactRef={contactRef}/>}
            </div>
        </div>
    );
}
