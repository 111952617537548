import React from "react";
import comcord_1 from "../../common/images/comcord_1.png";
import comcord_2 from "../../common/images/comcord_2.png";

export function PortfolioComcord(){
    return(
        <>
            <article className="prose pb-12 px-2">
                <h2 className="">Comcord</h2>
                <a className="link">https://www.comcord.app</a>
                <p>
                    A private client wanted a SaaS application which combined tweetdeck and discord to create an improved UI, the client working in the cryptocurrency sector had issues managing many discord chat groups and this software allieviated that, its also offered as a subscription service.
                </p>
                <h2>Tech Stack:</h2>
                <blockquote>
                    Django &#40;SaaSPegasus&#41;<br/>
                    React<br/>
                    PostgreSQL<br/>
                    Discord.py<br/>
                    Heroku<br/>
                </blockquote>

            </article>
            <div className="mockup-browser border bg-orange-400 w-full overflow-hidden max-w-[1000px] mb-24">
                <div className="mockup-browser-toolbar">
                    <a className="input">https://www.comcord.app</a>
                </div>
                <div className="flex justify-center bg-base-200">
                    <img src={comcord_1} alt="comcord" className="w-full"/>
                </div>
                <div className="flex justify-center bg-base-200">
                    <img src={comcord_2} alt="comcord" className="w-full"/>
                </div>
            </div>
        </>
    );
}
