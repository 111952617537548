import React, { RefObject } from 'react';

interface DesktopButtonsProps {
    howWeWorkRef: RefObject<HTMLDivElement>;
    serviceBespokeSoftware: RefObject<HTMLDivElement>;
    serviceExcelSheet: RefObject<HTMLDivElement>;
    serviceCustomSaaS: RefObject<HTMLDivElement>;
    portfolioRef: RefObject<HTMLDivElement>;
    contactRef: RefObject<HTMLDivElement>;
}

export function DesktopButtons({ howWeWorkRef, serviceBespokeSoftware, serviceExcelSheet, serviceCustomSaaS, portfolioRef, contactRef }: DesktopButtonsProps) {
    
    const scrollToHowWeWorkSection = () => {
        if (howWeWorkRef.current) {
            howWeWorkRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const scrollToServiceBespokeSoftware = () => {
        if(serviceBespokeSoftware.current) {
            serviceBespokeSoftware.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const scrollToServiceExcelSheet = () => {
        if(serviceExcelSheet.current) {
            serviceExcelSheet.current.scrollIntoView({ behavior: 'smooth' });
        }
    }
    
    const scrollToServiceCustomSaaS = () => {
        if(serviceCustomSaaS.current) {
            serviceCustomSaaS.current.scrollIntoView({ behavior: 'smooth' });
        }
    }

    const scrollToPortfolio = () => {
        if(portfolioRef.current) {
            portfolioRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const scrollToContact = () => {
        if(contactRef.current) {
            contactRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return(
        <div className="flex-none">

            <ul className="menu menu-horizontal px-1 text-black font-bold">
                <li>
                    <div onClick={scrollToHowWeWorkSection} className="dropdown dropdown-bottom dropdown-end">
                        <div tabIndex={0} role="button" className="btn">How we Work</div>
                    </div>
                </li>
            </ul>

            <ul className="menu menu-horizontal px-1 text-black font-bold">
                <li>
                    <div className="dropdown dropdown-bottom dropdown-end">
                        <div tabIndex={0} role="button" className="btn">Services</div>
                        <ul tabIndex={0} className="dropdown-content z-[1] menu shadow bg-base-100 rounded-box w-52">
                            <li><a onClick={scrollToServiceBespokeSoftware}>Bespoke Software</a></li>
                            <li><a onClick={scrollToServiceExcelSheet}>Excel Spreadsheet Replacement</a></li>
                            <li><a onClick={scrollToServiceCustomSaaS}>Custom SaaS Software</a></li>
                        </ul>
                    </div>
                </li>
            </ul>     
            
            <ul onClick={scrollToPortfolio} className="menu menu-horizontal px-1 text-black font-bold">
                <li>
                    <div className="dropdown dropdown-bottom dropdown-end">
                        <div tabIndex={0} role="button" className="btn">Portfolio</div>
                    </div>
                </li>
            </ul>
        
            <ul onClick={scrollToContact} className="menu menu-horizontal px-1 text-black font-bold">
                <li>
                    <div className="dropdown dropdown-bottom dropdown-end">
                        <div tabIndex={0} role="button" className="btn">Contact</div>
                    </div>
                </li>
            </ul>   

        </div>
    );
}