import React, { forwardRef } from "react";
import { PortfolioComcord } from "./PortfolioComcord";
import { PortfolioFastmeurs } from "./PortfolioFastmeurs";
import { PortfolioGnomeTrader } from "./PortfolioGnomeTrader";

export const Portfolio = forwardRef<HTMLDivElement, {}>((props, ref) => {
    return(
        <div ref={ref} className="flex flex-col items-center bg-base-200 portfolio-section">

            <div className="prose p-8">
                <h1>Portfolio</h1>
            </div>
            <PortfolioGnomeTrader/>
            <PortfolioComcord />
            <PortfolioFastmeurs/>
        </div>
    );
});