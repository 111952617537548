import React, { useState, RefObject } from "react";

interface MobileButtonsProps {
    howWeWorkRef: RefObject<HTMLDivElement>;
    serviceBespokeSoftware: RefObject<HTMLDivElement>;
    portfolioRef: RefObject<HTMLDivElement>;
    contactRef: RefObject<HTMLDivElement>;
}

export function MobileButtons({howWeWorkRef, serviceBespokeSoftware, portfolioRef, contactRef}: MobileButtonsProps){

    const [open, setOpen] = useState(false);

    const scrollToHowWeWorkSection = () => {
        if (howWeWorkRef.current) {
            howWeWorkRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const scrollToServiceBespokeSoftware = () => {
        if(serviceBespokeSoftware.current) {
            serviceBespokeSoftware.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const scrollToPortfolio = () => {
        if(portfolioRef.current) {
            portfolioRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const scrollToContact = () => {
        if(contactRef.current) {
            contactRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return(
        <div className="flex-none">
            <div className="dropdown dropdown-end">
                <div tabIndex={0} role="button" className="btn m-1" onClick={() => setOpen(!open)}>
                    {open ? <XCircle/> : <List/>}
                </div>
                {open && (
                    <ul tabIndex={0} className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-52">
                        <li><a onClick={() => {scrollToHowWeWorkSection(); setOpen(!open);}}>How we Work</a></li>
                        <li><a onClick={() => {scrollToServiceBespokeSoftware(); setOpen(!open);}}>Services</a></li>
                        <li><a onClick={() => {scrollToPortfolio(); setOpen(!open);}}>Portfolio</a></li>
                        <li><a onClick={() => {scrollToContact(); setOpen(!open);}}>Contact</a></li>
                    </ul>
                )}
            </div>
        </div>
    );
}

function XCircle(){
    return(
        <svg
         xmlns="http://www.w3.org/2000/svg" 
         width="16" 
         height="16" 
         fill="currentColor" 
         className="bi bi-x-circle" 
         viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
        </svg>
    );
}

function List(){
    return(
        <svg 
        xmlns="http://www.w3.org/2000/svg" 
        width="16" 
        height="16" 
        fill="currentColor" 
        className="bi bi-list" 
        viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"/>
        </svg>
    );
}
