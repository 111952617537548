import React from 'react';
import fastmeurs from '../../common/images/fastmeurs.png';

export function PortfolioFastmeurs(){
    return(
        <>
            <article className="prose pb-12 px-2">
                <h2 className="">Fastmeurs</h2>
                <a className="link">https://www.fastmeurs.com</a>
                <p>
                    A private client wanted a SaaS application to automate a highly repetative process in the food logistics industry. Aquiring meurs codes is a time sink for companies who export food into the EU, this automates the issue and provides a subscription service to the tool.
                </p>
                <h2>Tech Stack:</h2>
                <blockquote>
                    Django &#40;SaaSPegasus&#41;<br/>
                    React<br/>
                    PostgreSQL<br/>
                    MailGun<br/>
                    Heroku<br/>
                </blockquote>

            </article>
            <div className="mockup-browser border bg-blue-400 w-full overflow-hidden max-w-[1000px] mb-24">
                <div className="mockup-browser-toolbar">
                    <a className="input">https://www.fastmeurs.com</a>
                </div>
                <div className="flex justify-center bg-base-200">
                    <img src={fastmeurs} alt="fastmeurs" className="w-full"/>
                </div>
            </div>
            <br className="py-12"/>
        </>
    );
}