import React from "react";

// Define a type for the LogoSVG props
interface LogoSVGProps {
    color: string;
}

export function Logo() {
    return (
        <button className="btn btn-ghost flex items-center gap-2 relative">
            {/* Background animated logo */}
            <div className="flex items-center gap-2 absolute" style={{ margin: '-3px 0 0 -3px', opacity: 0.5 }}>
                <h1 className="text-xl font-extrabold" style={{ animation: 'colorCycle 3s infinite' }}>
                    Black Sun Systems
                </h1>
                {/* <LogoSVG className="color-cycle background-logo"/> */}
                <SunSVG className="color-cycle background-logo"/>

            </div>
            {/* Foreground static logo */}
            <h1 className="text-black text-xl font-extrabold z-10">
                Black Sun Systems
            </h1>
            {/* <LogoSVG className="text-black foreground-logo"/> */}
            <SunSVG className="text-black foreground-logo"/>
        </button>
    );
}



function LogoSVG({ className }: { className?: string }) {
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width="24" 
            height="24" 
            fill="currentColor" // Controlled by CSS now
            className={`bi bi-lightning-charge-fill ${className || ''}`} 
            viewBox="0 0 16 16">
            <path d="M11.251.068a.5.5 0 0 1 .227.58L9.677 6.5H13a.5.5 0 0 1 .364.843l-8 8.5a.5.5 0 0 1-.842-.49L6.323 9.5H3a.5.5 0 0 1-.364-.843l8-8.5a.5.5 0 0 1 .615-.09z"/>
        </svg>
    );
}

function SunSVG({ className }: { className?: string }){
    return(
        <svg 
        xmlns="http://www.w3.org/2000/svg" 
        width="16" 
        height="16" 
        fill="currentColor" 
        className={`bi bi-circle-fill ${className || ''}`} 
        viewBox="0 0 16 16">
            <circle cx="8" cy="8" r="8"/>
        </svg>
    );
}