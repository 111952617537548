import React, { useState, useEffect } from 'react';

export function Hero(){
    return(
        <div className="hero bg-base-200">
            <div className="hero-content flex-col lg:flex-row-reverse">
                <div className="mockup-code">
                    <pre data-prefix="1"><code>Custom SaaS Software</code></pre> 
                    <pre data-prefix="2"><code>Excel Sheet Replacement</code></pre>
                    <pre data-prefix="3"><code>Bespoke Software</code></pre>
                    
                    <pre data-prefix="4" className="bg-warning text-warning-content flex items-center">
                        <code>All work considered!</code>
                        <span className="flex-grow"></span>
                        <AnimatedSlash />
                    </pre>
                    
                    <pre data-prefix="5"><code>Call anytime: +44 07429316117</code></pre> 
                </div>
                <div className="m-4">
                    <h1 className="text-5xl font-bold">Bespoke Software <br/> and Business<br/>Automation</h1>
                    <p className="py-6">
                        We build custom software solutions<br/> for businesses of all sizes. 
                    </p>
                </div>
            </div>
        </div>
    );
}

const AnimatedSlash = () => {
    const characters = ['/', '-', '\\', '|'];
    const [currentChar, setCurrentChar] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentChar((prevChar) => (prevChar + 1) % characters.length);
        }, 200);

        return () => clearInterval(intervalId);
    }, []);

    return <code>{characters[currentChar]}</code>;
};