import React, {  forwardRef } from "react";

export const HowWeWork = forwardRef<HTMLDivElement, {}>((props, ref) => {

    return(
        <div ref={ref} className="flex flex-col items-center bg-base-200 how-we-work-section">
            <div className="prose p-8">
                <h1>How We Work</h1>
            </div>
            <ul className="timeline timeline-snap-icon max-md:timeline-compact timeline-vertical">
                <li>
                    <div className="timeline-middle">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clipRule="evenodd" /></svg>
                    </div>
                    <div className="timeline-start md:text-end mb-10">
                    <time className="font-mono italic bg-warning">Step 1</time>
                    <div className="text-lg font-black">Initial Conversation</div>
                    <div className="max-w-lg">Before diving into the technical details, we want to understand your business and your goals, we will ask the right questions to get the understanding we need of your business.</div>
                    </div>
                    <hr className="bg-red-500"/>
                </li>
                <li>
                    <hr className="bg-red-500"/>
                    <div className="timeline-middle">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clipRule="evenodd" /></svg>
                    </div>
                    <div className="timeline-end mb-10">
                    <time className="font-mono italic bg-warning">Step 2</time>
                    <div className="text-lg font-black">Solution Discussion</div>
                    <div className="max-w-lg">Once we understand your business, we will investigate the best solutions for your situation. We will then work with you to select the best way forward.</div>
                    </div>
                    <hr className="bg-red-500"/>
                </li>
                <li>
                    <hr className="bg-red-500"/>
                    <div className="timeline-middle">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clipRule="evenodd" /></svg>
                    </div>
                    <div className="timeline-start md:text-end mb-10">
                    <time className="font-mono italic bg-warning">Step 3</time>
                    <div className="text-lg font-black">Scoping</div>
                    <div className="max-w-lg">After discussing the various solutions, we will create a scope of the project to define specifically the objectives of the software. From here the costs of the solution will be better understood.</div>
                    </div>
                    <hr className="bg-red-500"/>
                </li>
                <li>
                    <hr className="bg-red-500"/>
                    <div className="timeline-middle">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clipRule="evenodd" /></svg>
                    </div>
                    <div className="timeline-end mb-10">
                    <time className="font-mono italic bg-warning">Step 4</time>
                    <div className="text-lg font-black">Specification Document</div>
                    <div className="max-w-lg">With the scope defined, we will create a specification for the project which ensures everyone is on the same page regarding its direction.</div>
                    </div>
                    <hr className="bg-red-500"/>
                </li>
                <li>
                    <hr className="bg-red-500"/>
                    <div className="timeline-middle">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clipRule="evenodd" /></svg>
                    </div>
                    <div className="timeline-start md:text-end mb-10">
                    <time className="font-mono italic bg-warning">Step 5</time>
                    <div className="text-lg font-black">Development</div>
                    <div className="max-w-lg">With everything in place, development will commence. We will discuss a meeting schedule to keep you up to date on the progress, this can be as frequent as you would prefer.</div>
                    </div>
                    <hr className="bg-red-500"/>
                </li>
                <li>
                    <hr className="bg-red-500"/>
                    <div className="timeline-middle">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clipRule="evenodd" /></svg>
                    </div>
                    <div className="timeline-end mb-10">
                    <time className="font-mono italic bg-warning">Step 6</time>
                    <div className="text-lg font-black">Testing</div>
                    <div className="max-w-lg">If required we will build a testing rig to ensure the software always works as expected, as the project approaches a useable state, we will do user acceptance testing which will reinform development and ensure we are on the right track.</div>
                    </div>
                </li>
            </ul>
        </div>
    );
});